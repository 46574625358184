import React from 'react';

import awsconfig from '../aws-exports';
import Amplify from 'aws-amplify';
import { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';


Amplify.configure(awsconfig);

// Apply plugin with configuration option
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'ap-southeast-2',
    aws_pubsub_endpoint: 'wss://a2n47cp6nfwqho-ats.iot.ap-southeast-2.amazonaws.com/mqtt'
}))


class SubTopic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sensorMsg: '{"null": 0}'
        };
    }

    componentDidMount() {
        PubSub.subscribe(this.props.topic).subscribe({
            next: data => {
                try {
                    this.setState({ sensorMsg: data.value[this.props.header] })
                }
                catch (error) {
                    console.log("Error, are you sending the correct data?");
                }
            },
            error: error => console.error(error),
            complete: () => console.log('Done'),
        });
    }
    render() {
        const { sensorMsg } = this.state;


        return (
            <>
                {sensorMsg[this.props.val]} {this.props.unit}
            </>
        );
    }
}

export default SubTopic;