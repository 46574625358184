import './App.css';

import { Auth, Amplify, API, graphqlOperation } from 'aws-amplify';
import { createLog, updateLog, deleteLog } from './graphql/mutations';

import React, { useState, useEffect } from 'react';
import Loader from './Loader'
import logo from './images/dbc-logo.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';
import { SwitchField } from '@aws-amplify/ui-react';

import MDAvatar from "./components/MDAvatar";
// @mui material components
import Grid from "@mui/material/Grid";
// Data

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Button from 'react-bootstrap/Button';

import ProbesCard from './components/probesCard'
import { UpdateCard } from './components/updateCard'
import Card from 'react-bootstrap/Card';

import { PubSub } from 'aws-amplify';


// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";


// Material Dashboard 2 React themes
import themeDark from "assets/theme-dark";
import theme from "assets/theme-dark";

import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import { DropUpdateCard } from './components/dropdownUpdateCard';
Amplify.configure(awsExports);

// Apply plugin with configuration option
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'ap-southeast-2',
  aws_pubsub_endpoint: 'wss://a2n47cp6nfwqho-ats.iot.ap-southeast-2.amazonaws.com/mqtt'
}))



function App({ signOut, user }) {
  /* create a todo */
  const [logged, setLogged] = useState(0)
  const formLog = async () => {
    await API.graphql(graphqlOperation(createLog, { input: { username: user.username, description: "user log in" } }));
  }

  if (logged === 0) {
    formLog()
    setLogged(1)
  }

  /* used to add user to iotpolicy
  Auth.currentCredentials().then((info) => {
    const cognitoIdentityId = info.identityId;
    console.log(cognitoIdentityId)
  });
*/
  

  const [sensorMsg, setsensorMsg] = useState('{ "null": "0" }')

  useEffect(() => {


    PubSub.subscribe('$aws/things/EdgeMain/shadow/update/documents').subscribe({
      next: data => {
        try {
          setsensorMsg(data.value.current.state.reported.tags.set1)
          //console.log("this")
          //console.log(data.value.current.state.reported.tags.set1)
        }
        catch (error) {
          console.log("Error, are you sending the correct data?");
        }
      },
      error: error => console.error(error),
      complete: () => console.log('Done')
    });
  }, []);


  return (
<ThemeProvider theme={themeDark}>
  <CssBaseline />
    <>
      <header className="App-header">
      <Grid container spacing={4}>
            <Grid item xs={4} md={4} align="left">
            <MDAvatar bgColor="light" src={logo} alt="Avatar" />
            </Grid>
            <Grid item xs={4} md={4} align="center">
        <p>Hello {user.username}</p>
              </Grid> 
        <Grid item xs={4} md={4} align="right">
         <Button  onClick={signOut}>Sign out</Button>
        </Grid>
        </Grid>
      
      </header>
      {sensorMsg !== '{ "null": "0" }' ? <div className="App-body">

      <MDBox mb={6}>
          <Grid container spacing={3}>
          <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={4} align="center">
            <Card style={{ width: '25rem' }}>
                <Card.Body>
                  <Card.Title align="center">DN Phase
                    <SwitchField
                      isDisabled={false}
                      label=""
                      isChecked={sensorMsg.DN_PHASE_REMAINING > "1" ? true : false}
                    /> {sensorMsg.DN_PHASE_REMAINING > "1" ? sensorMsg.DN_PHASE_REMAINING: "0"} mins
                  </Card.Title>
                  <Card.Text>
                    <UpdateCard name="DN Phase Time " pholder={sensorMsg.DN_Phase_Time} dat="DN_Phase_Time" min="0" max="540" unit="min" dig={[1, 2, 3, 5]} user={user} />
                    <UpdateCard name="DN ORP Aeration Start" pholder={sensorMsg.DN_ORP_Aeration_Start} dat="DN_ORP_Aeration_Start" min="-150" max="150" unit="mV" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="DN ORP Aeration Stop" pholder={sensorMsg.DN_ORP_Aeration_Stop} dat="DN_ORP_Aeration_Stop" min="-150" max="150" unit="mV" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="Sucrose Dosing Rate" pholder={sensorMsg.Sucrose_Dosing_Rate} dat="Sucrose_Dosing_Rate" min="0" max="250" unit="L/Day" user={user} dig={[1, 2, 3, 5]} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} align="center">
            <Card style={{ width: '25rem' }}>
                <Card.Body>
                  <Card.Title align="center">Anammox Phase
                  </Card.Title>
                  <Card.Text>
                    <UpdateCard name="Start On Cycle" pholder={sensorMsg.Anammox_start_on_cycle_number} dat="Anammox_start_on_cycle_number" min="0" max="3" unit="" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="Number Of Anammox Cycles" pholder={sensorMsg.Number_of_Anammox_Cycles} dat="Number_of_Anammox_Cycles" min="0" max="4" unit="" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="ORP Aeration Start" pholder={sensorMsg.Anammox_ORP_Aeration_Start} dat="Anammox_ORP_Aeration_Start" min="-150" max="150" unit="mV" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="ORP Aeration Stop" pholder={sensorMsg.Anammox_ORP_Aeration_Stop} dat="Anammox_ORP_Aeration_Stop" min="-150" max="150" unit="mV" user={user} dig={[1, 2, 3, 5]} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} align="center">
            <Card style={{ width: '25rem' }}>
                <Card.Body>
                  <Card.Title align="center">N Phase - Aeration <SwitchField isDisabled={false} label="" isChecked={sensorMsg.N_PHASE_REMAINING > "1" ? true : false} /> {sensorMsg.N_PHASE_REMAINING > "1" ? sensorMsg.N_PHASE_REMAINING : "0"} mins
                  </Card.Title>
                  <Card.Text>
                    <UpdateCard name="N Cycle Time" pholder={sensorMsg.N_Cycle_Time} dat="N_Cycle_Time" min="0" max="540" unit="min" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="ORP Aeration Start" pholder={sensorMsg.ORP_Aeration_Start} dat="ORP_Aeration_Start" min="-150" max="150" unit="mV" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="ORP Aeration Stop" pholder={sensorMsg.ORP_Aeration_Stop} dat="ORP_Aeration_Stop" min="-150" max="150" unit="mV" user={user} dig={[1, 2, 3, 5]} />
                  </Card.Text>
                </Card.Body>
              </Card>

            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={6}>
          <Grid container spacing={3}>
          <Grid item xs={12} md={4} align="center">
          <ProbesCard />
          </Grid>
          <Grid item xs={12} md={4} align="center">
          <Card style={{ width: '25rem' }}>
                  <Card.Body>
                    <Card.Title align="center">Sedimentation Area
                    </Card.Title>
                    <Card.Text>
                    <UpdateCard name="Sludge to Aeration" pholder={sensorMsg.Sludge_to_Aeration} dat="Sludge_to_Aeration" min="0" max="540" unit="min" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="Sludge to Tank" pholder={sensorMsg.Sludge_to_Anaerobic} dat="Sludge_to_Anaerobic" min="0" max="540" unit="min" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="Liquid to Tank Time ON" pholder={sensorMsg.Liquid_to_Anaerobic_Time_ON} dat="Liquid_to_Anaerobic_Time_ON" min="0" max="540" unit="min" user={user} dig={[1, 2, 3, 5]} />
                    <UpdateCard name="Liquid to Tank Time OFF" pholder={sensorMsg.Liquid_to_Anaerobic_Time_OFF} dat="Liquid_to_Anaerobic_Time_OFF" min="0" max="540" unit="min" user={user} dig={[1, 2, 3, 5]} />
                  </Card.Text>
                </Card.Body>
              </Card>
          <Card style={{ width: '25rem' }}>
            <Card.Body>
              <Card.Title align="center">Transfer Pump
                <SwitchField
                  isDisabled={false}
                  label=""
                  isChecked={sensorMsg.Pump_7 === "ON" ? true : false}
                />
              </Card.Title>
              <Card.Text>
                <UpdateCard name="Ferric Dosing Rate Auto" pholder={sensorMsg.Ferric_Dosing_Rate__Auto} dat="Ferric_Dosing_Rate__Auto" min="0" max="540" unit="mL/min" user={user} dig={[1, 2, 3, 5]} />
                <UpdateCard name="Ferric Dosing Rate Manual" pholder={sensorMsg.Ferric_Dosing_Rate__Manual} dat="Ferric_Dosing_Rate__Manual" min="0" max="750" unit="mL/min" user={user} dig={[1, 2, 3, 5]} />
                <UpdateCard name="Manual Floc Dosing ON/OFF" pholder={sensorMsg.Manual_Floc_Dosing_ONOFF} dat="Manual_Floc_Dosing_ONOFF" user={user} dig={[1, 2, 3, 5]} />
                <UpdateCard name="Current Ferric Dosing Rate" pholder={sensorMsg.Current_Ferric_Dosing_Rate} dat="Current_Ferric_Dosing_Rate" min="0" max="540" unit="mL/min" user={user} dig={[1, 2, 3, 5]} />
                <UpdateCard name="Pump 7 Flow Rate" pholder={sensorMsg.Pump_7_Flow_Rate} dat="Pump_7_Flow_Rate" unit="m3/h" user={user} dig={[1, 2, 3, 5]} />
              </Card.Text>
            </Card.Body>
          </Card>
          </Grid>
          <Grid item xs={12} md={4} align="center">
          <Card style={{ width: '25rem' }}>
          <Card.Title align="center">Aeration Control</Card.Title>
                <Card.Text>
                <Grid container spacing={3}>
                <Grid item xs={8} md={8}>
                <DropUpdateCard pholder={sensorMsg.aeratorcombo1} dat="aeratorcombo1" heading="Mixer 1" user={user} />
                </Grid>
                <Grid item xs={4} md={4}>
                <SwitchField isDisabled={false} label="" isChecked={sensorMsg.Blower_1 === "ON" ? true : false}/>
                </Grid>
                <Grid item xs={8} md={8}>
                <DropUpdateCard pholder={sensorMsg.aeratorcombo2} dat="aeratorcombo2" heading="Mixer 2" user={user}/>
                </Grid>
                <Grid item xs={4} md={4}>
                <SwitchField isDisabled={false} label="" isChecked={sensorMsg.Blower_2 === "ON" ? true : false} />
                </Grid>
                <Grid item xs={8} md={8}>
                <DropUpdateCard pholder={sensorMsg.aeratorcombo3} dat="aeratorcombo3" heading="Mixer 3" user={user}/>
                </Grid>
                <Grid item xs={4} md={4}>
                <SwitchField isDisabled={false} label="" isChecked={sensorMsg.Blower_3 === "ON" ? true : false}/>
                </Grid>
                <Grid item xs={8} md={8}>
                <DropUpdateCard pholder={sensorMsg.aeratorcombo4} dat="aeratorcombo4" heading="Mixer 4" user={user}/>
                </Grid>
                <Grid item xs={4} md={4}>
                <SwitchField isDisabled={false} label="" isChecked={sensorMsg.Blower_4 === "ON" ? true : false}/>
                </Grid>
                <Grid item xs={12} md={12}></Grid>
                </Grid>
                </Card.Text>
              </Card>
          </Grid>
          </Grid>
          </MDBox>
  
      </div> : <Loader />}

    </>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
