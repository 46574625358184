
//import { SliderField, Button, Flex, TextField } from '@aws-amplify/ui-react';

import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Grid from "@mui/material/Grid";



import awsconfig from '../aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { createLog, updateLog, deleteLog } from '../graphql/mutations';

import { Amplify, API, graphqlOperation } from 'aws-amplify';

//import sensorData from './sensorData';

Amplify.configure(awsconfig);

// Apply plugin with configuration option
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'ap-southeast-2',
  aws_pubsub_endpoint: 'wss://a2n47cp6nfwqho-ats.iot.ap-southeast-2.amazonaws.com/mqtt'
}))



export const DropUpdateCard = ({ pholder, dat, heading, user }) => {

  //logging to dynamo 
  const formLog = async (dat) => {
    await API.graphql(graphqlOperation(createLog, { input: { username: user.username, description: dat } }));
  }

  const [val, setVal] = useState("def")

  useEffect(() => {
    setTimeout(() => {
      switch (pholder) {
        case 0:
          setVal("Off")
          break;
        case 1:
          setVal("Mix Only")
          break;
        case 2:
          setVal("50 %")
          break;
        case 3:
          setVal("100 %")
          break;
        default:
          setVal("no data")
      }
    }, 1000)

  })

  const handleOnSubmit = (event) => {
    

    formLog({ [dat]: event })
    //console.log(event)
    PubSub.publish('$aws/things/EdgeMain/shadow/update',
      {
        "state": {
          "desired": {
            "tags": { "set1": { [dat]: event } }
          }
        }
      })
  }

  return (
  <>
  <Grid container spacing={3}>
                <Grid item xs={6} md={6} align="right">
                <DropdownButton id="dropdown-basic-button" title={heading} onSelect={handleOnSubmit}>
        <Dropdown.Item eventKey="0">Off</Dropdown.Item>
        <Dropdown.Item eventKey="1">Mix Only</Dropdown.Item>
        <Dropdown.Item eventKey="2">50%</Dropdown.Item>
        <Dropdown.Item eventKey="3">100%</Dropdown.Item>
      </DropdownButton>
                </Grid>
                <Grid item xs={6} md={6} align="center">
                SP: {val}
                </Grid>
                </Grid>
  </>




  );
};

