
//import { SliderField, Button, Flex, TextField } from '@aws-amplify/ui-react';

import React, { useState, useEffect } from 'react';


import awsconfig from '../aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { createLog, updateLog, deleteLog } from '../graphql/mutations';

import { Amplify, API, graphqlOperation } from 'aws-amplify';

//import sensorData from './sensorData';

Amplify.configure(awsconfig);

// Apply plugin with configuration option
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'ap-southeast-2',
  aws_pubsub_endpoint: 'wss://a2n47cp6nfwqho-ats.iot.ap-southeast-2.amazonaws.com/mqtt'
}))



export const UpdateCard = ({ name, pholder, dat, min, max, unit, user, dig }) => {

  const formLog = async (dat) => {
    await API.graphql(graphqlOperation(createLog, { input: { username: user.username, description: dat } }));
  }



  const handleOnSubmit = (event) => {
    event.preventDefault();
    formLog({ [dat]: event.target.input.value })

    PubSub.publish('$aws/things/EdgeMain/shadow/update',
      {
        "state": {
          "desired": {
            "tags": { "set1": { [dat]: event.target.input.value } }
          }
        }
      })
 

  }

  const [pub, setPub] = useState([])

  const onChange = (event) => {
    // Intended to run on the change of every form element
    event.preventDefault()
    setPub({ [event.target.name]: event.target.value, })
    console.log(pub)


  }

  /*
  
  <DnPhaseCard dig={[1, 2, 3, 5]} />
   
  return dig.map((item) => (
    <span className="indent">{item} </span>
  ));
  
  */

  return (
    <form onSubmit={handleOnSubmit}>
      <InputGroup className="mb-3">
        <InputGroup.Text>{name}</InputGroup.Text>
        <FormControl
          id="inputID"
          name="input"
          placeholder={pholder}
          aria-label="Enter New Value"
          type="number"
          min={min}
          max={max}
          onChange={onChange.bind(this)}

        //onKeyPress={this.handleSave}
        />
        <InputGroup.Text>{unit}</InputGroup.Text>
      </InputGroup>
    </form>
  );
};

