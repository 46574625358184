import logo from './images/silec.png';
import './App.css';

function Loader() {
  return (
      <div className="Loader-body">
        <img src={logo} className="logo" alt="logo" />
        <p className="txtglo">
          Loading Data, Please Wait.
        </p>
      </div>
  
  );
}

export default Loader;
