
import React from 'react';
import SubTopic from '../mqtt/subTopic';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';


export default class ProbesCard extends React.Component {


    render() {
        return (
            <Card style={{ width: '25rem' }}>
                <Card.Body >
                    <Card.Title align="center">Aeration Pond Values</Card.Title>
                    <Table className='tab-readings'>
                        <thead>
                            <tr>
                                <th>Probe</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Orp Probe</td>
                                <td><SubTopic val='orp' topic='pondsprobes' header='ponds_probes' unit='mV' /></td>
                            </tr>
                            <tr>
                                <td>Oxygen</td>
                                <td><SubTopic val='o2' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>NH4-N</td>
                                <td><SubTopic val='nh4n' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>NO2-N Raw</td>
                                <td><SubTopic val='no2n_raw' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>NO3-N Varion</td>
                                <td><SubTopic val='no3n_varion' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>NO3-N Spectral</td>
                                <td><SubTopic val='no3n_spectral' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>TSS</td>
                                <td><SubTopic val='tss' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>TSS Average</td>
                                <td><SubTopic val='tss_average' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>CoD-DS</td>
                                <td><SubTopic val='codds' topic='pondsprobes' header='ponds_probes' unit='mg/L' /></td>
                            </tr>
                            <tr>
                                <td>Temperature</td>
                                <td><SubTopic val='temp' topic='pondsprobes' header='ponds_probes' unit='°C' /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }


};

